import React from 'react';
import { ThemeContext } from '../ThemeContext';
import { Menu } from '../Menu/Menu';

import * as styles from './Header.module.css';

export const Header = ({ headerTransparent, hasAlertBar, yellowBusiness, sections }) => {
  const { bg, text } = React.useContext(ThemeContext);
  const [scrollY, setScrollY] = React.useState(0);
  const [heightOfHeader, setHeightOfHeader] = React.useState('0px');

  const headerEl = React.useRef(null);

  React.useLayoutEffect(()=>{
    // the scrollY is use to determine whether to set the nav transparent when scroll down
    function updateScrollY() {
      setScrollY(window.pageYOffset);
    }

    window.addEventListener('scroll', updateScrollY)

    // this is to identify the height of the menu to enable the hero banner to overlap
    function getHeaderHeight() {
      setHeightOfHeader(headerEl?.current?.clientHeight || 0);
    }
    getHeaderHeight();
    window.addEventListener('resize', getHeaderHeight);

    return () => {
      window.removeEventListener('scroll', updateScrollY);
      window.removeEventListener('resize', getHeaderHeight);
    }
  }, []);

  return (
    <header
      id="header"
      ref={headerEl}
      className={`
        py-3 md:py-5 shadow-md z-50 top-0 w-full sticky 
        ${bg('highlight-dark')} ${text('highlight-light')} 
        ${styles['header']}
        ${(headerTransparent && scrollY < ((hasAlertBar)?90:10)) && styles['headerTransparent'] || ''}
      `}
      style={headerTransparent && {
        marginBottom: `-${heightOfHeader}px`,
      }}
    >
      <div className="max-width-wrapper">
        <Menu
          className=""
          yellowBusiness={yellowBusiness}
          sections={sections}
        />
      </div>
    </header>
  );
};

