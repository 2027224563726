import React from 'react';

import { HTMLPageHeader } from '../HTMLPageHeader/HTMLPageHeader';
import { Footer } from '../Footer/Footer';
import { ThemeContext } from '../ThemeContext';
import AlertBar from '../AlertBar';
import { Header } from '../Header/Header';

export const Layout = ({
    headerTransparent, 
    children, 
    pageName = 'Home',
    yellowBusiness,
    sections
}) => {
  const { bg, text } = React.useContext(ThemeContext);

  const hasAlertBar = !!yellowBusiness?.website?.alertBar?.text;

  return (
    <div className="min-h-screen min-w-full">
      {hasAlertBar && (<AlertBar alertBar={yellowBusiness?.website?.alertBar || null} />)}

      <div className={`flex flex-col items-center ${bg('base-light')} ${text('base-dark')} antialiased`}>
        <Header headerTransparent={headerTransparent} hasAlertBar={hasAlertBar} yellowBusiness={yellowBusiness} sections={sections} />
        <HTMLPageHeader title={pageName} business={yellowBusiness} />

        <main className={`flex-1 max-width-wrapper flex flex-col space-y-10 lg:space-y-16 ${!headerTransparent && 'pt-10'} pb-10 lg:pb-16`}>
          {children}
        </main>
        <Footer yellowBusiness={yellowBusiness} />
      </div>
    </div>
  );
}