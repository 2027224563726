import React from 'react';

import { Headlines } from '../..';
import { CoverImage } from '../CoverImage';

import { ThemeContext } from '../ThemeContext';

export const SectionHeroBanner = ({ yellowBusiness }) => {
  const { bg } = React.useContext(ThemeContext);

  return (
    <div className={`relative w-full ${bg('base-dark')} overflow-hidden`} style={{
      margin: '0 calc(50% - 50vw)',
      width: '100vw',
    }}>
      <Headlines
        className="max-width-wrapper absolute inset-0 z-10"
        headline={yellowBusiness?.website?.headline}
        subHeadline={yellowBusiness?.website?.subHeadline}
      />
      <CoverImage
        className="inset-0 z-0"
        yellowBusiness={yellowBusiness}
      />
    </div>
  )
}
