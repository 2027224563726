import {
  IServersideBusiness,
  IYellowBusiness,
  ThemeVariant,
} from '@websites/model';
import { yellowBusinessToLocalBusinessJSONLD } from './yellowBusinessToLocalBusinessJSONLD';

export const serverBusinessToClientBusiness: (args: {
  serverBusiness: IServersideBusiness;
  defaultTheme: ThemeVariant;
  overrideHeadline?: string;
  overrideSubHeadline?: string;
}) => IYellowBusiness = ({
  serverBusiness,
  defaultTheme,
  overrideHeadline,
  overrideSubHeadline,
}) => {
  const {
    headline,
    alternativeHeadline,
    alertBar,
    faqs,
    slug,
    greeting,
    primaryImageOfPage,
    gatsbyImagePrimaryImageOfPage,
  } = serverBusiness.providerConfiguration.yellowWebsite;

  const clientBusiness: IYellowBusiness = {
    name: serverBusiness.details.name,
    description: serverBusiness.details.description,
    locations: serverBusiness.locations,
    products: serverBusiness.products,
    logo: serverBusiness.details.logo,
    socialLinks: serverBusiness.details.sameAs,
    website: {
      headline: overrideHeadline || headline,
      subHeadline: overrideSubHeadline || alternativeHeadline,
      slug,
      theme: process.env.GATSBY_WEBSITES_THEME
        ? (`${process.env.GATSBY_WEBSITES_THEME}` as ThemeVariant)
        : defaultTheme,
      faqs,
      greeting,
      coverImage: primaryImageOfPage,
      gatsbyImageCoverImage: gatsbyImagePrimaryImageOfPage,
      alertBar,
    },
  };

  clientBusiness.website.jsonLD = yellowBusinessToLocalBusinessJSONLD(
    clientBusiness,
  );

  return clientBusiness;
};
