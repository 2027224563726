import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt as phoneIcon } from '@fortawesome/free-solid-svg-icons';

import { fireClickAnalyticsEvent, fireContactAnalyticsEvent, formattedDefaultPhoneNumber } from '@websites/utils';
import { ThemeContext } from '../ThemeContext';
import { LogoOrName } from '../LogoOrName/LogoOrName';
import { MenuHeaderToggleButton } from '../MenuHeaderToggleButton/MenuHeaderToggleButton';

export const Menu = ({ yellowBusiness, sections, className }) => {
  const formattedPhoneNumber = formattedDefaultPhoneNumber(yellowBusiness);
  const { text } = React.useContext(ThemeContext);

  return (
    <div
      className={`${className}
        w-full
        mx-auto
        text-sm md:text-base lg:text-lg ${text('base-light')}
        flex justify-between`}
    >
      <LogoOrName
        businessLogo={yellowBusiness?.logo}
        name={yellowBusiness?.name}
      />
      <div className="flex items-center">
        <nav className="hidden md:flex items-center space-x-4 lg:space-x-5">
          {sections && Object.values(sections).filter(Boolean).map((section, sectionIndex) => {
            return (
              <Link
                className="text-base hover:underline"
                to={section?.link || '/'}
                onClick={() => fireClickAnalyticsEvent('link_click', section?.name)}
                key={section?.name || sectionIndex}
              >
                {section?.name}
              </Link>
            )
          })}
        </nav>
        {formattedPhoneNumber && (
          <a
            className="ml-5 text-right lg:font-bold flex items-center space-x-2"
            rel="noopener"
            href={`tel:${formattedPhoneNumber.replace(' ', '')}`}
            onClick={() => {
              fireContactAnalyticsEvent('contact_link_clicked', 'phone');
            }}
          >
            <FontAwesomeIcon icon={phoneIcon} />
            <span>{formattedPhoneNumber}</span>
          </a>
        )}
        <div className="block md:hidden ml-4">
          <MenuHeaderToggleButton
            sections={sections}
            yellowBusiness={yellowBusiness}
          />
        </div>
      </div>
    </div>
  );
};