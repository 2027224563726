import React from 'react';
import Img, { FluidObject } from 'gatsby-image';

interface IFluidImage {
  fluid?: FluidObject
  src?: string
  alt?: string
  aspectRatio?: number
}

const FluidImage = ({
  fluid,
  src,
  alt,
  aspectRatio = 1,
}: IFluidImage) => {
  const percentageRatio = `${((1 / aspectRatio) * 100).toString()}%`;

  if (!fluid && !src) return null;

  return (
    <div
      className="relative h-0 overflow-hidden"
      style={{ paddingTop: percentageRatio }}
    >
      {fluid ? (
        <Img
          className="absolute inset-0"
          fluid={fluid}
          alt={alt}
          style={{ position: 'absolute' }} // because gatsby-image tries to overwrite it
          imgStyle={{ objectFit: 'cover' }} // because className goes to container, not to the image
        />
      ) : (
        <img
          className="absolute inset-0 w-full h-full object-cover"
          src={src}
          alt={alt}
        />
      )}
    </div>
  );
};

FluidImage.defaultProps = {
  fluid: null,
  src: '',
  alt: '',
  aspectRatio: 1,
};

export default FluidImage;
