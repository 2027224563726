import React, { FC } from 'react';
import {
  formatOpeningHours,
  formatPhoneNumber,
} from '@websites/utils';
import { IBusinessHours, ILocation } from '@websites/model';
import { ThemeContext } from '../ThemeContext';
import { getGoogleMapsIframeParameters } from './services/getGoogleMapsIframeParameters';

const OpeningHours: React.FC<{ openingHours: IBusinessHours[] }> = ({
  openingHours,
}) => {
  const formatedOpeningHours = formatOpeningHours(openingHours);
  if (!formatedOpeningHours) {
    return <></>;
  }
  return (
    <div>
      <div className="uppercase tracking-wide font-bold text-xs opacity-50">
        opening hours
      </div>
      <table>
        <tbody>
          {formatedOpeningHours.map((oh, ix) => (
            <tr key={ix}>
              <td>{oh.days}</td>
              <td className="pl-2">{oh.hours}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const InnerLocation = ({ loc }) => {
  return (
    <div className="text-left space-y-4">
      {loc.address && (
        <div>
          <div>
            {loc.address.streetNumber && (
              <span>{loc.address.streetNumber}&nbsp;</span>
            )}
            {loc.address.streetName && (
              <span>{loc.address.streetName}</span>
            )}
          </div>
          <div>
            {loc.address.sublocality && (
              <span>{loc.address.sublocality},&nbsp;</span>
            )}
          </div>
          <div>
            {loc.address.locality && (
              <span>{loc.address.locality}</span>
            )}
            {loc.address.postalCode && (
              <span>&nbsp;{loc.address.postalCode}</span>
            )}
          </div>
        </div>
      )}
      {formatPhoneNumber(loc.telephone) && (
        <div>
          <div className="uppercase tracking-wide font-bold text-xs opacity-50">
            phone
          </div>
          <div>{formatPhoneNumber(loc.telephone)}</div>
        </div>
      )}
      {loc.openingHours && (
        <div>
          <OpeningHours openingHours={loc.openingHours} />
        </div>
      )}
    </div>
  );
};

export const Locations: FC<{ locations: ILocation[] }> = ({ locations }) => {
  if (!locations || locations.length < 1) {
    return <p>No location found.</p>;
  }

  const locationsWithMaps = locations.filter((loc) => loc.hasMap);
  const { border, rounded } = React.useContext(ThemeContext);

  if (locationsWithMaps && locationsWithMaps.length < 1) {
    return (
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 w-full">
        {locations.map((loc: ILocation, ix: number) => (
          <div key={ix} className={`flex bg-white p-4 lg:p-5 xl:p-6 shadow ${rounded('lg')} border-t-4`}>
            <InnerLocation loc={loc}/>
          </div>
        ))}
      </div>
    );
  }

  const [selectedLocation, setSelectedLocation] = React.useState(
    locationsWithMaps[0]
  );

  return (
    <div className="flex flex-col w-full space-y-5">
      <div
        className={`${rounded(
          'lg'
        )} overflow-hidden border border-white shadow-md h-108`}
      >
        <iframe
          //! TODO should this iframe be sandboxed?
          title="Google Maps"
          width="100%"
          height="100%"
          src={`https://www.google.com/maps/embed/v1/place?key=${
            process.env.GATSBY_WEBSITES_GOOGLE_MAPS_API_KEY
          }&q=${getGoogleMapsIframeParameters(selectedLocation)}`}
        ></iframe>
      </div>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
        {locationsWithMaps.map((loc: ILocation, ix: number) => (
          <button
            key={ix}
            onClick={() => setSelectedLocation(loc)}
            className={`flex bg-white p-4 lg:p-5 xl:p-6 shadow ${rounded(
              'lg'
            )} border-t-4 ${
              loc === selectedLocation
                ? ` ${border('highlight-dark')}`
                : `border-transparent hover:${border(
                    'highlight-dark'
                  )} hover:border-opacity-50`
            }`}
          >
            <InnerLocation loc={loc}/>
          </button>
        ))}
      </div>
    </div>
  );
};
