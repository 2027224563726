import React from 'react';
import { ThemeContext } from '@websites/components';
import { slug } from '@websites/utils';

import { useLocation } from '@reach/router'

export const Section = ({
  section,
  children,
}) => {
  const theme = React.useContext(ThemeContext);
  const { border, text } = theme;

  const location = useLocation();
  const [firstLoad, setFirstLoad] = React.useState(true);

  React.useEffect(() => {
    if (location.hash) {
      const routeHash = window.location.hash.replace('#', '');
      const scrollToSection = document.querySelector(`#custom_${routeHash}`);

      if (scrollToSection) {
        const sectionOffsetTop = scrollToSection.offsetTop;

        const headerEl = document.querySelector('#header');
        const headerHeight = headerEl?.clientHeight || 70;

        // weird bug where on first load, I had to double the header height because of the position sticky (?) Not too sure, but it fixed the issue
        const headerOffset = headerHeight * ((firstLoad)?2:1) + 15;

        window.scroll({
          top: sectionOffsetTop - headerOffset,
          behavior: 'smooth'
        });
      }
    }

    setFirstLoad(false);
  }, [location])
  
  return (
    <section
      className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-2 h-full w-full"
      id={`custom_${slug(section)}`}
    >
      <div className="flex flex-col" style={{ flex: 1 }}>
        <div className={`w-20 ${border('highlight-dark')} border-b-4 mb-1`}></div>
        <h2
          className={`${text('title')} lg:pr-20 ${
            theme.isSharp
              ? 'text-md md:text-lg lg:text-xl font-title uppercase tracking-wider'
              : 'text-xl md:text-2xl lg:text-3xl font-bold'
          }`}
        >
          {section?.name}
        </h2>
      </div>
      <div className="flex flex-wrap h-full" style={{ flex: 3 }}>
        {children}
      </div>
    </section>
  );
};
